export const data = () => ({
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data',
      children: [
        {
          id: 'data_inicio',
          name: 'De',
        },
        {
          id: 'data_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Fornecedor',
      children: [
        {
          id: 'produto_desc',
          name: 'Produto',
        },
        {
          id: 'sub_produto_desc',
          name: 'Sub Produto',
        },
      ],
    },
    {
      id: 30,
      name: 'Contratos',
      children: [
        {
          id: 'terminal_cnpj',
          name: 'Terminal',
        },
      ],
    },
  ],
})
