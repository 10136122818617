export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor_cnpj: ["produto_desc", "sub_produto_desc", "cliente", "transportadora"]
    },
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
      },
      proprietario: {
        value: '',
        visible: false,
      },
      fornecedor_cnpj: {
        value: '',
        visible: false,
        get: this.getFornecedores,
      },
      produto_desc: {
        value: '',
        visible: false,
      },
      sub_produto_desc: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        get: this.getClientes,
      },
      transportadora: {
        value: '',
        visible: false,
        get: this.getTransportadoras,
      },
      terminal_cnpj: {
        value: '',
        visible: false,
        get: this.getContratos,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
    },
  }
}
