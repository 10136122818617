export const data = function () {
  return {
    dialogIndicadores: false,
    showComprovante: false,
    showComprovanteLaudo: false,
    showComprovanteAgendamento: false,
    page: {
      title: 'Relatório de Cotas Unidade',
    },
    breadcrumbs: [
      {
        text: 'Relatórios',
        disabled: false,
      },
      {
        text: 'Relatório de Cotas Unidade',
        disabled: false,
      },
    ],
  }
}
